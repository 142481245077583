<template>
    <section>
        <div class="subtitle">Última consulta oftalmológica</div>
        <div >Há {{ screening?.lastVisitInMonths }} Meses</div>

        <div class="subtitle">Condições da saúde</div>
        <div >{{ screening?.pathologiesUnderTreatment?.join(', ') }} </div>

        <div class="subtitle">Uso de medicamento contínuo?</div>
        <div >{{ screening?.usesContinuousMeds }} </div>
        <div v-if="screening?.usesContinuousMeds === 'Sim'" >{{ screening?.medicinesList?.join(', ') }} </div>

        <div class="subtitle">Sintomas visuais</div>
        <div >{{ screening?.eyeDiscomfort?.join(', ') || 'Nenhum' }} </div>

        <div class="subtitle">Condições oculares grave</div>
        <div >{{ screening?.eyeConditions?.join(', ') || 'Nenhuma' }} </div>

        <div class="subtitle">Uso de óculos ou lentes</div>
        <div >{{ screening?.glassesOrLenses ? 'Sim' : 'Não' }} </div>

        <div v-if="screening?.glassesOrLenses === 'Não'">
            <div class="subtitle">Considera que enxerga bem sem usar óculos ou lentes?</div>
            <div >{{ screening?.seeWellWithoutGlasses || 'Sem resposta' }} </div>

            <div class="subtitle">Já recebeu alguma receita de óculos?</div>
            <div >{{ screening?.alreadyHadPrescription || 'Sem resposta' }} </div>

            <div class="subtitle">Conseguiria informar o grau que foi passado na receita de óculos?</div>
            <div >{{ screening?.canInformPrescriptionDegree || 'Sem resposta' }} </div>
        </div>

        <div class="subtitle">Acesso a última prescrição</div>
        <div >{{ screening?.hasLastPrescription || 'Sem resposta' }} </div>

        <div v-if="screening?.hasLastPrescription === 'Sim' && screening?.informDegree">
            <div class="subtitle">Grau da prescrição </div>
            <b>Olho direito:</b>
            <div class="inline">
                <div>Esférico:<b>{{ screening.informDegree.esferico_D }}</b></div>
                <div>Cilíndrico:<b>{{ screening.informDegree.cilindrico_D }}</b></div>
                <div>Eixo:<b>{{ screening.informDegree.eixo_D }}</b></div>
            </div>
            <b>Olho esquerdo:</b>
            <div class="inline">
                <div>Esférico:<b>{{ screening.informDegree.esferico_E }}</b></div>
                <div>Cilíndrico:<b>{{ screening.informDegree.cilindrico_E }}</b></div>
                <div>Eixo:<b>{{ screening.informDegree.eixo_E }}</b></div>
            </div>
        </div>


        <div class="subtitle">Útiliza óculos de proteção</div>
        <div >{{ screening?.hasEPI }} </div>

        <div v-if="screening?.hasEPI === 'Sim'">
            <div class="subtitle">Óculos de proteção possui grau</div>
            <div >{{ screening?.epiHasDegreeCorrection || 'Sem resposta' }} </div>

            <div class="subtitle">O grau do seu EPI (equipamento de proteção individual) é o mesmo do óculos que você usa no dia a dia?</div>
            <div >{{ screening?.hasEPISameDegreeAsGlasses || 'Sem resposta' }} </div>

            <div v-if="screening?.informDegreeEPI">
                <div class="subtitle">Grau do óculos de proteção</div>
                <div class="inline">
                    <div>Esférico: <b>{{ screening?.informDegreeEPI?.esferico_E }}</b></div>
                    <div>Cilíndrico: <b>{{ screening?.informDegreeEPI?.cilindrico_E }}</b></div>
                    <div>Eixo: <b>{{ screening?.informDegreeEPI?.eixo_E }}</b></div>
                </div>
                <div class="inline">
                    <div>Esférico: <b>{{ screening?.informDegreeEPI?.esferico_D }}</b></div>
                    <div>Cilíndrico: <b>{{ screening?.informDegreeEPI?.cilindrico_D }}</b></div>
                    <div>Eixo: <b>{{ screening?.informDegreeEPI?.eixo_D }}</b></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: {
            loading: Boolean,
            screening: Object
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        margin-top: 2rem;
        color: var(--dark-blue, #0C1D59);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .subtitle {
        color: var(--type-active, #525C7A);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        margin-top: 0.6rem;
    }
    .time {
        color: var(--blue-500, #305BF2);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
</style>